@import "../../../../../shared/styles/vars";
@import "../../../../../shared/styles/mixins";

.page-title {
  position: relative;
  color: transparent;
  width: 100%;

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    text-transform: uppercase;
    z-index: 1;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    text-shadow: 10px 5px 0px #ff4d07;

    @include breakpoint_MaxWidth($bp-1768) {
      text-shadow: 7px 4px 0px #ff4d07;
    }
    @include breakpoint_MaxWidth($bp-1680) {
      text-shadow: 6px 4px 0px #ff4d07;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      text-shadow: 4px 3px 0px #ff4d07;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      text-shadow: 2px 2px 0px #ff4d07;
    }
    //&:after {
    //  position: absolute;
    //  content: attr(data-text);
    //  top: 5px;
    //  //left: 10px;
    //  right: 20px;
    //  color: #ff4d07;
    //  text-transform: uppercase;
    //  z-index: -1;
    //  width: 97%;
    //  display: flex;
    //  justify-content: center;
    //}
  }
}
