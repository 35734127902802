@import "../../shared/styles/vars";
@import "../../shared/styles/mixins";

.cookie-policy {
  height: 100%;

  &__subtitle {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 28px;

    @include breakpoint_MaxWidth($bp-900) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    & .underlined-title {
      padding-bottom: 4px;
      margin: 0;
      color: #FFFFFF;
    }

    div {
      margin-top: 0.5rem;
    }
  }

  &__second {
    margin-top: 48px;

    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 24px;
    }
  }

  &__info {
    margin: 30px 0;

    @include breakpoint_MaxWidth($bp-900) {
      margin: 20px 0;
    }

    @include breakpoint_MaxWidth($mobile-390) {
      margin: 16px 0;
    }
  }

  &__topic {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
}
