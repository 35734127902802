$ant-spin-prefix: ant-spin;

.loader-icon {
  font-size: 60px;
  height: 60px;
  width: 60px;
}

.ant-spin-container.ant-spin-blur {
  opacity: 1;
}

.loader-svg {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
