@mixin scrollbars($size, $color) {
  overflow: auto;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: #00d1ff;
    border-radius: 3px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $color;
  }

  &::-webkit-scrollbar-track {
    background: black;
  }

  //&::-webkit-scrollbar {
  //  width: 5px;
  //  height: 5px;
  //  transition: all 0.7s ease;
  //}
  //
  //&::-webkit-scrollbar:hover {
  //  width: 8px;
  //  height: 8px;
  //  cursor: pointer;
  //}
  //
  //&::-webkit-scrollbar-track {
  //  background: white;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  border-radius: 3px;
  //  background: dimgray;
  //}
}

//Breakpoint mixins

@mixin breakpoint_MaxWidth($bp) {
  @media (max-width: $bp) {
    @content;
  }
}

@mixin breakpoint_MinWidth($bp) {
  @media (min-width: $bp) {
    @content;
  }
}
