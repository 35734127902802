@import "../../../shared/styles/vars";
@import "../../../shared/styles/mixins";

.text-page-layout {
  height: 100%;
  padding-top: 5.4rem;

  @include breakpoint_MaxWidth($bp-1100) {
    padding-top: 71px;
  }
  @include breakpoint_MaxWidth($bp-900) {
    padding-top: 97px;
  }
  @include breakpoint_MaxWidth($mobile-xl) {
    padding-top: 102px;
  }
  @include breakpoint_MaxWidth($mobile-l) {
    padding-top: 130px;
  }
  @include breakpoint_MaxWidth($mobile-390) {
    padding-top: 100px;
  }
  @media screen and(max-width: 340px){
    padding-top: 99px;
  }

  &__header {
    background: url("../../../assets/images/cookiePolicy/back_02.jpg") no-repeat left bottom/cover;
    font-weight: bold;
    font-size: 96px;
    line-height: 112px;
    padding: 130px 0 117px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    @include breakpoint_MaxWidth($bp-1768) {
      font-size: 72px;
      line-height: 96px;
      padding: 110px 0 100px;
    }
    @include breakpoint_MaxWidth($bp-1680) {
      font-size: 64px;
      line-height: 72px;
      padding: 100px 0 90px;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 48px;
      line-height: 56px;
      padding: 61px 0 63px;
    }
    @include breakpoint_MaxWidth($bp-900) {
      font-size: 36px;
      line-height: 42px;
      padding: 43px 0 45px;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      padding: 21px 0;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__body {
    background-image: url("../../../assets/images/cookiePolicy/Group 641-min.png");
    background-attachment: fixed;
    font-size: 18px;
    line-height: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 60px 192px 94px;

    @include breakpoint_MaxWidth($bp-1680) {
      padding: 60px 160px 94px;
    }
    @include breakpoint_MaxWidth($bp-1536) {
      padding: 60px 80px 56px;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      padding: 56px 48px 36px;
    }
    @include breakpoint_MaxWidth($bp-1366) {
      background-image: url("../../../assets/images/cookiePolicy/background-1366.png");
    }
    @include breakpoint_MaxWidth($bp-900) {
      padding: 36px 24px 24px;
      font-size: 16px;
      line-height: 19px;
    }
    @include breakpoint_MaxWidth($tablet) {
      background-image: url("../../../assets/images/cookiePolicy/background-768.png");
      background-size: cover;
    }
    @include breakpoint_MaxWidth($mobile-390) {
      padding: 16px 16px 24px;
    }
    @include breakpoint_MaxWidth($mobile-s) {
      background-image: url("../../../assets/images/cookiePolicy/background-360.png");
    }

    & ul {
      @include breakpoint_MaxWidth($tablet) {
        padding-left: 30px;
      }
      @include breakpoint_MaxWidth($mobile-l) {
        padding-left: 25px;
      }
    }

    &_content {
      max-width: 1536px;
    }
  }
}
