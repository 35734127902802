@import "../../../shared/styles/vars";
@import "../../../shared/styles/mixins";

.menu-panel {
  background: url("../../../assets/images/menu 1.jpg") no-repeat left bottom/cover;
  height: 100vh;
  width: 26rem;
  position: fixed;
  top: 0;
  right: -40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.5rem;
  padding: 1.7rem 2.6rem;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.04);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 900;

  @include breakpoint_MaxWidth($bp-1440) {
    width: 550px;
    background: url("../../../assets/images/menu-1366.png") no-repeat center/cover;
  }

  @include breakpoint_MaxWidth($bp-900) {
    width: 575px;
  }

  @include breakpoint_MaxWidth($mobile-480) {
    width: 100%;

  }

  &.open {
    right: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &_item {
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.3rem;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: #ff6b00;
      }
    }
  }
}

.close-button {
  align-self: flex-end;
  font-size: 1.7rem;
  cursor: pointer;
  &:hover {
    color: #ff6b00;
  }
  & svg {
    @include breakpoint_MaxWidth($bp-1440) {
      width: 32px !important;
      height: 32px !important;
    }
  }
}
