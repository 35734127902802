@import "src/shared/styles/mixins";

.page-layout {
  width: 100%;
  height: 100%;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 16;
    width: 100%;
  }

  &__content {
    height: 100%;
  }

  .top-arrow {
    font-size: 2rem;
  }
}
