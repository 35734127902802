@import "mixins";
@import "src/shared/styles/fonts";
@import "../../../node_modules/antd/dist/antd.compact.min";
@import "vars";

html {
  font-size: 16px;

  //@media (max-width: 1400px) {
  //  font-size: 14px;
  //}
  //
  //@media (max-width: 1250px) {
  //  font-size: 12px;
  //}
  //
  //@media (max-width: 1100px) {
  //  font-size: 10px;
  //}
  //
  //@media (max-width: 900px) {
  //  font-size: 8px;
  //}
  //
  //@media (max-width: 700px) {
  //  font-size: 6px;
  //}
  //
  //@media (max-width: 650px) {
  //  font-size: 5px;
  //}
  //
  //@media (max-width: 550px) {
  //  font-size: 4px;
  //}
  //
  //@media (max-width: 375px) {
  //  font-size: 3px;
  //}
  //
  //@media (max-width: 225px) {
  //  font-size: 1.5px;
  //}
}

body {
  font-family: "Roboto", "sans-serif";
  margin: 0;
  color: white;
  //@include scrollbars(7px, #ff4d07);
  overflow-x: hidden;
}

.App {
  width: 100vw;
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active {
  //   -webkit-text-fill-color: white !important;
  //   transition: background-color 5000s ease-in-out 0s;
  // }
}

.text {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.title {
  font-weight: bold;
  font-size: 4.5rem;
  line-height: 5.25rem;
  text-transform: uppercase;
}

.ant-back-top  {
  width: 76px;
  height: 72px;
  right: 71px;
  bottom: 56px;

  @include breakpoint_MaxWidth($bp-1280) {
    width: 42px;
    height: 40px;
  }

  @include breakpoint_MaxWidth($bp-900) {
    right: 23px;
    bottom: 31px;
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}