@import "../../../shared/styles/vars";
@import "../../../shared/styles/mixins";

.header {
  width: 100%;
  background-color: black;
  padding: 0.3rem 2rem;
  display: flex;
  align-items: center;
  height: 5.4rem;
  justify-content: space-between;

  @include breakpoint_MaxWidth($bp-1440) {
    padding: 6px 28px 6px 32px;
    height: auto;
  }

  @include breakpoint_MaxWidth($mobile-l) {
    padding: 31px 16px 9px;
  }

  &__title {
    display: flex;
    align-items: center;

    &_name {
      color: white;
      font-size: 1.5rem;
      line-height: 1.75rem;
      padding-top: 5px;
      padding-left: 1.4rem;
      display: flex;
      align-items: flex-end;
      gap: 0.56rem;

      @include breakpoint_MaxWidth($bp-1440) {
        padding: 24px 0 17px 24px;
      }

      @include breakpoint_MaxWidth($bp-1100) {
        padding: 14px 0 17px 5px;
      }

      @include breakpoint_MaxWidth($bp-900) {
        display: flex;
        align-items: center;
      }

      @include breakpoint_MaxWidth($mobile-390) {
        padding: 0;
        gap: 0
      }

      span {
        margin-right: 0.6rem;

        @include breakpoint_MaxWidth($bp-1440) {
          font-size: 24px;
          line-height: 28px;
          margin-right: 12px;
        }

        @include breakpoint_MaxWidth($mobile-album) {
          font-size: 18px;
          line-height: 20px;
          margin-right: 8px;
        }

        @include breakpoint_MaxWidth($mobile-l) {
          font-size: 16px;
          line-height: 18px;
        }
        @include breakpoint_MaxWidth($mobile-390) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  &__menu {
    cursor: pointer;
  }
}

.ooyoot-logo {
  background: url("../../../assets/images/gatia_labs_logo-min.png") no-repeat left bottom/contain;
  width: 19.4rem;
  height: 4.6rem;

  @include breakpoint_MaxWidth($bp-1440) {
    width: 311px;
    height: 74px;
  }

  @include breakpoint_MaxWidth($bp-1100) {
    width: 234px;
    height: 53px;
  }

  @include breakpoint_MaxWidth($bp-900) {
    background: url("../../../assets/images/gatia_labs_logo-mobile.png") no-repeat left bottom/contain;
    width: 60px;
    height: 60px;
    margin-right: 16px;
  }

  @include breakpoint_MaxWidth($mobile-l) {
    margin-right: 13px;
  }

  @include breakpoint_MaxWidth($mobile-s) {
    margin-right: 8px;
  }
  @media screen and(max-width: 340px) {
    width: 55px;
    height: 55px;
  }

  &.link {
    cursor: pointer;
  }
}

.game-name {
  background: url("../../../assets/images/Group.png") no-repeat left bottom/contain;
  width: 47.25rem;
  height: 2.19rem;

  @include breakpoint_MaxWidth($bp-1440) {
    height: 34px;
    width: 756px;
  }

  @include breakpoint_MaxWidth($bp-1366) {
    width: 738px;
  }

  @include breakpoint_MaxWidth($bp-1280) {
    height: 28px;
    width: 517px;
  }

  @include breakpoint_MaxWidth($bp-1100) {
    width: 430px;
    height: 24px;
  }

  @include breakpoint_MaxWidth($bp-900) {
    background: url("../../../assets/images/nc-name-768.png") no-repeat left bottom/contain;
    width: 304px;
    height: 54px;
  }

  @include breakpoint_MaxWidth($mobile-album) {
    width: 275px;
  }

  @include breakpoint_MaxWidth($mobile-xl) {
    background: url("../../../assets/images/nc-game-footer-mobile.png") no-repeat left bottom/contain;
    width: 119px;
    height: 59px;
  }

  @include breakpoint_MaxWidth($mobile-s) {
    width: 110px;
  }

  &.link {
    cursor: pointer;
  }
}
