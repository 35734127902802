.menu-button {
  position: relative;
  z-index: 1;

  &__lines {
    position: relative;
    display: block;
    width: 43px;
    height: 22px;

    &_line {
      position: absolute;
      left: 0;
      height: 4px;
      width: 100%;
      background-color: #8ef8d2;
      box-shadow: 0 0 0 #8ef8d2;
      -webkit-transition: width 0.3s ease, box-shadow 0.2s ease;
      -moz-transition: width 0.3s ease, box-shadow 0.2s ease;
      transition: width 0.3s ease, box-shadow 0.2s ease;

      &:after {
        position: absolute;
        display: block;
        content: "";
        left: 3px;
        top: 2px;
        background-color: red;
        height: 100%;
        width: 100%;
        -webkit-transition: left 0.2s ease-out, top 0.2s ease-out;
        -moz-transition: left 0.2s ease-out, top 0.2s ease-out;
        transition: left 0.2s ease-out, top 0.2s ease-out;
        z-index: -1;
      }

      &.line-1 {
        top: 0;
      }

      &.line-2 {
        top: 9px;
      }

      &.line-3 {
        bottom: 0;
      }
    }

    &:hover {
      .menu-button__lines_line {
        box-shadow: 0 0 50px rgba(0, 209, 255, 0.25), 0 0 50px rgba(0, 209, 255, 0.25), 0 0 50px rgba(0, 209, 255, 0.25),
          0 0 50px rgba(0, 209, 255, 0.25);
        &:after {
          left: 0;
          top: 0;
        }
      }
    }
  }
}
