@import "../../../shared/styles/vars";
@import "../../../shared/styles/mixins";

.button {
  position: relative;
  color: transparent;
  height: 4.25rem;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  width: min-content;
  white-space: nowrap;

  @include breakpoint_MaxWidth($bp-1440) {
    width: 100%;
  }

  @include breakpoint_MaxWidth($bp-1280) {
    height: 100%;
    padding: 0;
  }

  & span {
    width: 100%;
    //height: 100%;

    @include breakpoint_MaxWidth($bp-1280) {
      width: unset;
      height: unset;
    }

    & svg {
      width: 22px !important;
      height: 17px !important;
    }
  }

  &__top {
    white-space: nowrap;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 4.25rem;
    background-color: white;
    color: #ff4d07;
    line-height: 1.75rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    z-index: 2;
    cursor: pointer;
    border: none;
    font: 900 1.5rem Roboto;
    box-shadow: -5px -5px 20px rgba(255, 77, 7, 0.15), -5px -5px 20px rgba(255, 77, 7, 0.15),
      -5px -5px 20px rgba(255, 77, 7, 0.15), -5px -5px 20px rgba(255, 77, 7, 0.15);
    transition: right 0.2s ease-in-out, bottom 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    @include breakpoint_MaxWidth($bp-1440) {
      width: 100%;
      padding: 21px 17px;

      & span svg {
        width: 38px;
        height: 30px;
      }
    }

    @include breakpoint_MaxWidth($bp-1280) {
      height: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      & span svg {
        width: 22px !important;
        height: 17px !important;
      }
    }

    &:hover {
      bottom: -5px;
      right: -5px;
      box-shadow: -5px -5px 20px rgba(0, 209, 255, 0.15), -5px -5px 20px rgba(0, 209, 255, 0.15),
        -5px -5px 20px rgba(0, 209, 255, 0.15), -5px -5px 20px rgba(0, 209, 255, 0.15);
    }
  }

  &__back {
    white-space: nowrap;
    height: 4.25rem;
    position: absolute;
    background: #00d1ff;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    color: #00d1ff;
    right: -5px;
    bottom: -5px;
    z-index: 1;
    box-shadow: 5px 5px 20px rgba(0, 209, 255, 0.15), 5px 5px 20px rgba(0, 209, 255, 0.15),
      5px 5px 20px rgba(0, 209, 255, 0.15), 5px 5px 20px rgba(0, 209, 255, 0.15);

    @include breakpoint_MaxWidth($bp-1440) {
      width: 100%;
    }

    @include breakpoint_MaxWidth($bp-1280) {
      height: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      & span svg {
        width: 22px !important;
        height: 17px !important;
      }
    }
  }

  &.disabled {
    .button__top {
      bottom: -5px;
      right: -5px;
      box-shadow: none;
      background-color: #e6e6e6;
      color: #b34505;

      &:hover {
        bottom: -5px;
        right: -5px;
        box-shadow: none;
      }
    }

    .button__back {
      box-shadow: none;
    }
  }

  &.small {
    height: 45px;
    font-size: 18px;
    line-height: 21px;

    .button__top {
      height: 45px;
      font-size: 18px;
      line-height: 21px;
      &:hover {
        bottom: -3px;
        right: -3px;
      }
    }

    .button__back {
      height: 45px;
      font-size: 18px;
      line-height: 21px;
      right: -3px;
      bottom: -3px;
    }
  }

  &.danger {
    .button__top {
      background-color: #ff4d07;
      color: white;
      box-shadow: none;
      &:hover {
        box-shadow: -5px -5px 20px rgba(255, 77, 7, 0.15), -5px -5px 20px rgba(255, 77, 7, 0.15),
          -5px -5px 20px rgba(255, 77, 7, 0.15), -5px -5px 20px rgba(255, 77, 7, 0.15);
      }
    }

    .button__back {
      background: white;
      color: white;
      box-shadow: 5px 5px 20px rgba(255, 77, 7, 0.15), 5px 5px 20px rgba(255, 77, 7, 0.15),
        5px 5px 20px rgba(255, 77, 7, 0.15), 5px 5px 20px rgba(255, 77, 7, 0.15);
    }
  }

  &.grey {
    .button__top {
      background-color: #828282;
      color: white;
      box-shadow: none;
      &:hover {
        box-shadow: -5px -5px 20px rgba(255, 255, 255, 0.15), -5px -5px 20px rgba(255, 255, 255, 0.15),
          -5px -5px 20px rgba(255, 255, 255, 0.15), -5px -5px 20px rgba(255, 255, 255, 0.15);
      }
    }

    .button__back {
      background: white;
      color: white;
      box-shadow: 5px 5px 20px rgba(255, 255, 255, 0.15), 5px 5px 20px rgba(255, 255, 255, 0.15),
        5px 5px 20px rgba(255, 255, 255, 0.15), 5px 5px 20px rgba(255, 255, 255, 0.15);
    }
  }
}
