@import "../../../shared/styles/vars";
@import "../../../shared/styles/mixins";

.footer {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  gap: 1rem;
  position: relative;

  @include breakpoint_MaxWidth($bp-1440) {
    padding: 48px 0 51px 0;

    & a .ooyoot-logo {
      background: url("../../../assets/images/gatia_labs_logo-min.png") no-repeat left bottom/contain;
      width: 449px;
      height: 107px;

      @include breakpoint_MaxWidth($bp-1100) {
        width: 327px;
        height: 77px;
      }
    }
  }

  @include breakpoint_MaxWidth($bp-900) {
    padding: 13px 68px 23px;
  }

  @include breakpoint_MaxWidth($mobile-album) {
    padding: 24px 24px 36px 16px;
  }

  a {
    .ooyoot-logo {
      @include breakpoint_MaxWidth($mobile-390) {
        margin-right: 0;
        height: 74px;
        width: 312px;
      }
      @media screen and(max-width: 350px){
        width: 300px;
      }
    }
  }

  &__title {
    margin-top: 1.2rem;
    margin-bottom: 1.5rem;

    @include breakpoint_MaxWidth($bp-1440) {
      margin-top: 37px;
      margin-bottom: 48px;
      width: 837px;
      height: 38px;
      background-size: cover !important;
    }

    @include breakpoint_MaxWidth($bp-1100) {
      background: url('../../../assets/images/nc-name-768.png');
      width: 346px;
      height: 62px;
    }

    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 20px;
      margin-bottom: 24px;
    }

    @media screen and($tablet) {
      width: 230px;
      background-size: contain;
    }

    @include breakpoint_MaxWidth($mobile-album) {
      background: url("../../../assets/images/nc-game-footer-mobile.png");
      height: 129px;
      width: 292px;
    }

  }

  &__links {
    display: flex;
    gap: 4.4rem;

    @media screen and(max-width: 760px) {
      gap: 30px
    }

    @include breakpoint_MaxWidth(&mobile-album) {

    }

    @include breakpoint_MaxWidth($mobile-xl) {
      display: grid;
      grid-template-columns: 110px 110px;
      grid-row-gap: 16px;
      grid-column-gap: 32px;
      width: 100%;
      justify-content: center;
    }

    @include breakpoint_MaxWidth($mobile-390) {
      justify-content: flex-start;
    }


    span {
      color: white;
      font-size: 1.1rem;
      line-height: 1.3rem;
      text-decoration: underline;
      cursor: pointer;

      @include breakpoint_MaxWidth($bp-1440) {
        font-size: 18px;
        line-height: 21px;
      }

      @include breakpoint_MaxWidth($tablet) {
        font-size: 16px;
        line-height: 19px;
      }


      &:hover {
        color: #ff6b00;
        text-decoration: none;
      }
    }
  }

  &__social {
    display: flex;
    gap: 2.5rem;
    margin-top: 2rem;
    align-items: center;

    @include breakpoint_MaxWidth($bp-1440) {
      margin-top: 48px;
    }

    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 24px;
    }

    @include breakpoint_MaxWidth($mobile-390) {
      gap: 34px;
    }
    
    @media screen and(max-width: 350px) {
      gap: 20px
    }

    a {
      color: white;

      & svg {
        @include breakpoint_MaxWidth($bp-1440) {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    line-height: 1.3rem;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    @include breakpoint_MaxWidth($bp-1440) {
      font-size: 18px;
      line-height: 30px;
    }

    @include breakpoint_MaxWidth($bp-900) {
      margin-top: 24px;
    }

    @include breakpoint_MaxWidth($mobile-album) {
      max-width: 308px;
      text-align: center;
    }
  }

  &__age {
    display: inline-flex;
    position: absolute;
    right: 10%;
    bottom: 60px;

    @include breakpoint_MaxWidth($bp-1768) {
      right: 11%;
    }
    @include breakpoint_MaxWidth($bp-1536) {
      right: 11.5%;
    }
    @include breakpoint_MaxWidth($bp-1440) {
      right: 12%;
    }
    @include breakpoint_MaxWidth($bp-1366) {
      right: 15%;
      width: 40px;
      height: 40px;
      bottom: 52px;
    }
    @include breakpoint_MaxWidth($bp-1280) {
      right: 12%;
    }
    @include breakpoint_MaxWidth($bp-1100) {
      right: 14%;
    }
    @include breakpoint_MaxWidth($bp-900) {
      bottom: 32px;
      right: 86px;
    }
    @media screen and(max-width: 760px){
      bottom: 42%;
      right: 2.5%;
    }
    @include breakpoint_MaxWidth($mobile-album) {
      bottom: 41%;
      right: 2%;
    }
    @media screen and(max-width: 536px) {
      right: 5.5%;
    }
    & span svg {
      width: 68px;
      height: 68px;

      @include breakpoint_MaxWidth($bp-1280) {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.twitter-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    color: #ff6b00;
  }
}

.instagram-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    color: #ff6b00;
  }
}

.discord-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    color: #ff6b00;
  }
}

.telegram-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    color: #ff6b00;
  }
}

.medium-icon {
  font-size: 3.2rem;
  cursor: pointer;
  &:hover {
    color: #ff6b00;
  }
}

.mastodon-icon {
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    color: #ff6b00;
  }
}



